/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import React, { useLayoutEffect, useState, useEffect } from "react"
import kebabCase from "lodash/kebabCase"
import get from "lodash/get"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { useMedia } from "react-use"

import { renderLineBreak, findBySlug } from "../helpers"

import Carousel from "../components/carousel"
import Column from "../components/column"
import Container from "../components/container"
import CTA from "../components/cta"
import BisnowHero from "../components/bisnow-campaign-hero"
import Hero  from "../components/tahoe-campaign-hero"
import ProjectGalleryModule from "../components/project-gallery-module"
import Row from "../components/row"
import Text from "../components/text"
import plantPrefabLogo from "../images/PP-logo-white-sm (1).svg"
import phoneicon from "../images/phone-icon.svg"
import emailicon from "../images/email-icon.svg"
import certified from "../images/2018-B-Corp-Logo-White.svg"
import PageLink from "../components/page-link"
import RichText from "../components/rich-text"
import BenefitsModule from "../components/benefits-module"
import { useIsClient } from "../hooks/use-is-client"

const Builders23Campaign = ({ data }) => {
  const [innerwidth, setinnerwidth] = useState(null)
  const [availablerwidth, setAvailableWidth] = useState(null)
  const [mediaQueryMatch, setmediaQueryMatch] = useState(false)
  const isClient = useIsClient()
  if (isClient)
    window.addEventListener("resize", () => {
      setAvailableWidth(window.screen.availWidth)
      setinnerwidth(window.innerWidth)
    })
  useEffect(() => {
    if (isClient) {
      setAvailableWidth(window.screen.availWidth)
      setinnerwidth(window.innerWidth)
      document.getElementsByTagName("header")[0].getElementsByTagName("a")[0].setAttribute("href","#")
      document.getElementsByTagName("header")[0].getElementsByTagName("a")[0].setAttribute("onclick","return false;")
    }
  }, [isClient])

  useEffect(() => {
    if (availablerwidth > innerwidth) {
      if (innerwidth < 992) {
        setmediaQueryMatch(true)
        return
      }
    } else {
      if (availablerwidth < 992) {
        setmediaQueryMatch(true)
        return
      }
    }
    if (availablerwidth > innerwidth) {
      if (innerwidth >= 992) {
        setmediaQueryMatch(false)
        return
      }
    } else {
      if (availablerwidth >= 992) {
        setmediaQueryMatch(false)
        return
      }
    }
  }, [innerwidth, availablerwidth])

  const renderHeroBladePattern = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "outreach-builders-23-campaign-or-hero-module",
    })
    const heroHeights = [null, null, 549]

    if (!heroBladePatternData) return
    // TODO: Stacked mobile hero
    const ctaList = []

    if (heroBladePatternData.ctaTitle) {
      ctaList.push({
        link: heroBladePatternData?.contentCards[0]?.ctaDestination,
        type: "secondary",
        text: heroBladePatternData?.contentCards[0]?.ctaTitle,
      })
    }
    if (!heroBladePatternData.contentCards) return
    return (
      <>
        {/* <Carousel type="quaternary" autoScroll={true} delayTime={3000}>
          {heroBladePatternData.contentCards.map(contentCard => ( */}
            <BisnowHero
              customStyle={{
                height: heroHeights,
                maxHeight: heroHeights,
              }}
              background={{
                type: "image",
                source: {
                  mobileImage: heroBladePatternData.contentCards[0].mobileImage,
                  desktopImage: heroBladePatternData.contentCards[0].desktopImage,
                },
              }}
              title={heroBladePatternData.contentCards[0].title}
              body="Deliver projects 20-50% faster with superior quality and cost control."
              ctaList={[
                {
                  link: heroBladePatternData.contentCards[0].ctaDestination,
                  type: "secondary",
                  text: heroBladePatternData.contentCards[0].ctaTitle,
                },
              ]}
              type="secondary"
              textBackground="#31aabb"
            />
          {/* ))}
          </Carousel> */}
      </>
    )
  }
  const renderLetsTalk = () => {
    const designMattersBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "tahoe-builders-23-campaign-or-lets-talk",
    })

    if (!designMattersBladePatternData) return
    const description = get(
      designMattersBladePatternData,
      "contentCards[0].description"
    )

    return (
      <div
        sx={{
          width: "100%",
          overflow: "hidden",
          mb: [50, null, null, null],
        }}
      >
        <Container
          customStyle={{
            backgroundColor: theme => [
              theme.colors.backgrounds.primary,
              theme.colors.white,
            ],
            py: theme => [
              theme.spacing.vertical.sm,
              theme.spacing.vertical.sm,
              theme.spacing.vertical.md,
            ],
            // maxWidth: [null, null, containerMaxWidth, null],
          }}
        >
          <Row customStyle={{ px: [0, 0, null, null] }}>
            <Column
              size={[12, 12, 4]}
              customStyle={{
                mb: theme => [theme.spacing.vertical.sm, null],
              }}
            >
              <Text type="h2" customStyle={{ marginBottom: "20px" }}>
                {renderLineBreak(designMattersBladePatternData.title)}
              </Text>
              {/* <div>
                <img src={phoneicon} sx={{ width: "35px" }} />
                <Text
                  type="h5"
                  customStyle={{
                    color: "#31aabb",
                    marginBottom: "10px",
                    display: "inline-block",
                    position: "relative",
                    top: "-9px",
                    marginLeft: "10px",
                  }}
                >
                  <a href="tel:909.546.7444"> 909.546.7444</a>
                </Text>
              </div> */}
              <div>
                <CTA
                    link="/contact?leadsourcedetail=Outbound%20Contact%20Form"
                    type="secondary"
                    linkType="internal"
                    customStyle={{
                      my: ["20px", "20px", null],
                      mr: ["10px"],
                      fontSize: "15px",
                    }}
                  >
                    Submit Project Inquiry
                  </CTA>
              </div>
              <div>
                <img src={emailicon} sx={{ width: "35px" }} />
                <Text
                  type="h5"
                  customStyle={{
                    color: "#31aabb",
                    marginBottom: "10px",
                    display: "inline-block",
                    position: "relative",
                    top: "-9px",
                    marginLeft: "10px",
                  }}
                >
                     <a href="mailto:info@plantprefab.com"> Email Us</a>
                </Text>
                
              </div>
              
            </Column>
            <Column size={[12, 12, 8, 7]}>
              <>
            {designMattersBladePatternData.contentCards.map((basicCard, idx) => (
                <Row
                  key={idx}
                  customStyle={{
                    mb: theme => theme.spacing.vertical.sm,
                    "p:last-of-type": {
                      mb: 0,
                    },
                  }}
                >
                  <Text type="h3">{basicCard.title}</Text>
                  {basicCard.description ? (
                    <div>
                      <RichText
                        data={basicCard.description}
                        textStyle={
                          !basicCard.title
                            ? {
                                mt: "-10px !important",
                              }
                            : null
                        }
                      />
                    </div>
                  ) : null}
                  {basicCard.ctaTitle ? (
                    <CTA
                      customStyle={{
                        mt: 20,
                      }}
                      link={
                        (basicCard.ctaDestination &&
                          basicCard.ctaDestination.ctaDestination) ||
                        (basicCard.ctaDestinationExternal &&
                          basicCard.ctaDestinationExternal
                            .ctaDestinationExternal) ||
                        "/"
                      }
                      type={"secondary"}
                      linkType={
                        basicCard.ctaDestinationExternal
                          ? "external"
                          : "internal"
                      }
                      
                    >
                      {basicCard.ctaTitle}
                    </CTA>
                  ) : null}
                </Row>
              ))}
              </>
              {/* <div
                    sx={{
                      mt: theme => [20, 20, theme.spacing.vertical.md],
                    }}
                  >
                    <CTA
                      link="/contact?leadsourcedetail=Bisnow%20Contact%20Form"
                      type="secondary"
                      linkType="internal"
                    >
                      Submit a Project Inquiry
                    </CTA>
                  </div> */}
              
            </Column>
          </Row>
        </Container>
      </div>
    )
  }

  

  const renderSupport = () => {
    return (
      <div sx={{ backgroundColor: "#286c7f" }}>
        <Container
          customStyle={{
            py: theme => [
              theme.spacing.vertical.md,
              theme.spacing.vertical.md,
              theme.spacing.vertical.lg,
            ],
          }}
        >
          <Row customStyle={{ px: [0, 0, null, null] }}>
            <Column
              customStyle={{
                mt: theme => [50, 50, 0],
              }}
              size={[12, 12, 6, 5]}
              // key={kebabCase(contentCard.title)}
            >
              <Text type="h2" customStyle={{ color: "#fff" }}>
                See how Plant Prefab can support your project.
              </Text>
              <div
                sx={{
                  mt: theme => [20, 20, theme.spacing.vertical.sm],
                  display: "inline",
                }}
              >
                <CTA
                  link="/contact?leadsourcedetail=Outbound%20Contact%20Form "
                  type="secondary"
                  linkType="internal"
                  customStyle={{
                    my: ["20px", "20px", null],
                    mr: ["10px"],
                    fontSize: "15px",
                  }}
                >
                  Contact Us
                </CTA>
                {mediaQueryMatch && <br />}
                {/* <Text
                  type="h5"
                  customStyle={{
                    display: "inline",
                    color: "#31aabb",
                    marginLeft: [null, null, null, "10px"],
                   
                  }}
                >
                  <a
                    href="tel:909.546.7444"
                  >
                    {" "}
                    909.546.7444
                  </a>
                </Text> */}
                <Text
                  type="h5"
                  customStyle={{
                    display: "inline",
                    color: "#31aabb",
                    // marginLeft: ["10px", null, null, "10px"],
                    // borderLeft: "1px solid #31aabb",
                    // paddingLeft:["4px","0px",null,"4px"]
                    marginLeft: [null, null, null, "10px"],
                  }}
                >
                  <a href="mailto:info@plantprefab.com"> Email Us</a>
                </Text>
              </div>
            </Column>
            <Column size={[0, 0, 1, 3]}></Column>
            <Column
              customStyle={{
                marginRight: 0,
                marginLeft: "auto",
                margin: "auto",
                mt: theme => [20, 20, 20, 0],
                display: "inline-block",
              }}
              size={[12, 12, 5, 4]}
            >
              <Row customStyle={{ px: [0, 0, null, null] }}>
                <Column size={[12, 12, 6]}>
                   <img
                      sx={{
                        height: "90px",
                        width: "160px",
                        m: 0,
                      }}
                      src={plantPrefabLogo}
                      alt="Plant Prefab Logo"
                    />
                </Column>
                <Column
                  size={[0, 0, 0, 1]}
                  customStyle={{ display: ["none", null, "block", "block"] }}
                >
                  <div
                    sx={{ borderLeft: "1px solid #fff ", height: "100px" }}
                  ></div>
                </Column>
                <Column size={[12, 12, 4]}>
                  <img
                    sx={{
                      height: [160, 160, 100, 100],
                      width: ["100px", "100px", "auto", 250],
                      m: 0,
                      mt: [10, 10, 0, 0],
                    }}
                    src={certified}
                    alt="Plant Prefab Logo"
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </div>
    )
  }

  const renderHero2BladePattern = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "builders-23-company-hero-module",
    })
    const heroHeights = ["auto", 555]
    if (!bladePatternData) return
    const ctaList = bladePatternData.ctaTitle && [
      {
        text: bladePatternData.ctaTitle,
        type: "secondary",
        link: bladePatternData.ctaDestination || "/",
      },
    ]
    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            mobileImage: bladePatternData.mobileImage,
            desktopImage: bladePatternData.desktopImage,
          },
        }}
        verticalAlign="center"
        title={bladePatternData.title}
        body={bladePatternData.description.description}
        ctaList={ctaList}
        type="secondary"
        popupVideo={get(
          bladePatternData,
          "contentCards[0].ctaDestinationExternal.ctaDestinationExternal"
        )}
      />
    )
  }

  const renderBenefitsModule = () => {
    const benefitsModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "builders-23-benefits-module",
    })
    if (!benefitsModuleBladePatternData) return

    return <BenefitsModule bladePatternData={benefitsModuleBladePatternData} />
  }
  const renderProjectGalleryModule = () => {
    const projectGalleryModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "builders-23-gallery-module",
    })

    if (!projectGalleryModuleBladePatternData) return

    return (
      <ProjectGalleryModule
        bladePatternData={projectGalleryModuleBladePatternData}
        projectsData={data.allContentfulProject}
        customStyle={{ pb: "61%", height: 0 }}
      />
    )
  }
  return (
    <div>
      {renderHeroBladePattern()}
      {renderLetsTalk()}
      {renderProjectGalleryModule()}
      {renderBenefitsModule()}
      {renderHero2BladePattern()}
      {renderSupport()}
    </div>
  )
}

export default Builders23Campaign

export const query = graphql`
  query builders23Campaign {
    contentfulPage(slug: { eq: "builders-23" }) {
      ...PageData
      ...BladePatternData
    }
  }
`
